import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {OverlayInjectedProps} from 'react-bootstrap/esm/Overlay';
import {BsQuestionCircle} from 'react-icons/bs';

export type HelpWikiLinkProps = {
	link: string;
	name?: string;
};

function HelpWikiLink({link, name}: HelpWikiLinkProps) {
	const renderTooltip = (props: OverlayInjectedProps) => (
		<Tooltip {...props}>
			<div className="text-small">
				Open wiki article: <a href={link} target="_blank">{name ? name : link}</a>
			</div>
		</Tooltip>
	);
	return (
		<OverlayTrigger
			placement="top"
			delay={{show: 250, hide: 400}}
			overlay={renderTooltip}
		>
			<a className="btn btn-link" href={link} target="_blank">
				<BsQuestionCircle/>
			</a>
		</OverlayTrigger>
	);
}

export default HelpWikiLink;
