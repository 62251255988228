import React, {useEffect, useRef, useState} from 'react';
import {WithOnAlertProp} from "../../../../type/component/WithAlertProps";
import {ImportStashDocumentFull} from "../../../../type/data/ImportStashDocumentFull";
import {loadImportStashDocumentFull} from "../../../../util/restUtil";
import {formatJsonForHumans, isJson} from "../../../../util/jsonUtil";
import {Button, Form, Stack} from "react-bootstrap";
import {formatXmlForHumans, isXml} from "../../../../util/xmlUtil";
import {formatHtmlForHumans, isHtml} from "../../../../util/htmlUtil";
import {BsArrowLeft, BsArrowRight} from "react-icons/bs";
import {isEmptyString} from "../../../../util/stringUtil";
import Loading from "../../../controls/Loading";
import {CancellablePromise, StringUtil} from "incomaker-react-ts-commons";


export type FeedBrowserDetailProps = WithOnAlertProp & {
	id: string;
	search?: string | null;
}

function FeedBrowserDetail({id, search, onAlert}: FeedBrowserDetailProps) {
	const [full, setFull] = useState<ImportStashDocumentFull | null>(null);
	const [content, setContent] = useState<string>("");
	const contentRef = useRef<HTMLTextAreaElement>(null);

	const [loading, setLoading] = useState<boolean>(false);
	const [loadingPromise, setLoadingPromise] = useState<CancellablePromise | null>(null);

	const [searchMatches, setSearchMatches] = useState<number[]>([]);
	const [searchIndex, setSearchIndex] = useState<number>(0);

	const [selectedFormatting, setSelectedFormatting] = useState<string>("auto");
	const [detectedFormatting, setDetectedFormatting] = useState<string>("none");

	const loadDocument = () => {
		if (loadingPromise) {
			loadingPromise.cancel();
		}
		setLoading(true);
		const lpp = new CancellablePromise(loadImportStashDocumentFull(id));
		lpp.promise.then(
			(result) => {
				setLoading(false);
				setFull(result);
			}
		).catch(
			(err) => {
				setLoading(false);
				setFull(null);
				onAlert({type: 'danger', title: 'Error', message: String(err)});
			}
		);
		setLoadingPromise(lpp);
	}

	useEffect(loadDocument, [id]);

	const formatContent = () => {
		setSearchIndex(0);
		setSearchMatches([]);
		if (!full) setContent("loading...");

		let detected = "none";
		if (isEmptyString(full?.content)) {
			detected = "empty!";
		} else if (isJson(full?.content)) {
			detected = "json";
		} else if (isHtml(full?.content)) {
			detected = "html";
		} else if (isXml(full?.content)) {
			detected = "xml";
		}
		setDetectedFormatting(detected);

		let final = (selectedFormatting === "auto") ? detected : selectedFormatting;

		switch (final) {
			case "empty!":
				setContent("no bytes...");
				break;
			case "json":
				setContent(formatJsonForHumans(full?.content));
				break;
			case "xml":
				setContent(formatXmlForHumans(full?.content));
				break;
			case "html":
				setContent(formatHtmlForHumans(full?.content));
				break;
			default:
				setContent(full ? full.content : "");
		}
	}

	useEffect(formatContent, [full, selectedFormatting]);

	const buildSearchMatches = () => {
		if (isEmptyString(content) || isEmptyString(search)) {
			setSearchMatches([]);
			return;
		}

		const regexp = new RegExp(String(search), "gu");
		const matches = content.matchAll(regexp);
		const am = Array.from(matches).map(m => Number(m.index));
		setSearchMatches(am);
	}

	useEffect(buildSearchMatches, [search, content]);

	const searchContent = () => {
		if (searchMatches.length <= 0) return;
		if (!contentRef.current) return;

		if (searchIndex < 0) {
			setSearchIndex(searchMatches.length);
			return;
		}
		if (searchIndex >= searchMatches.length) {
			setSearchIndex(0);
			return;
		}

		const start = searchMatches[searchIndex];
		contentRef.current.focus();
		contentRef.current.setSelectionRange(start, start + String(search).length);

		// @ts-ignore
		if (typeof window.find === 'function') {
			// @ts-ignore
			window.find(search);
		}
	}

	useEffect(searchContent, [searchMatches, searchIndex]);


	return (
		<div>
			<Form className="pb-2">
				<Stack direction="horizontal" className="d-flex align-items-center justify-content-between">
					<div className="filter-bar d-flex align-items-center  gap-2">
						<Form.Label for="formatting">Formatting:</Form.Label>
						<Form.Select
							id="formatting"
							value={selectedFormatting}
							onChange={(e) => setSelectedFormatting(e.target.value)}
						>
							<option value="none">None</option>
							<option value="auto">Auto</option>
							<option value="json">JSON</option>
							<option value="xml">XML</option>
							<option value="html">HTML</option>
						</Form.Select>
						<div><strong>{detectedFormatting}</strong></div>
					</div>
					{
						full?.url && (
							<small>
								<a href={full?.url} target="_blank">
									{StringUtil.ellipsis(full?.url, 50)}
								</a>
							</small>
						)
					}
					<div className="d-flex align-items-center gap-2">
						<span>{searchMatches.length > 0 ? searchIndex + 1 : 0} / {searchMatches.length} matches</span>
						<Button
							disabled={searchMatches.length === 0}
							onClick={() => setSearchIndex(searchIndex - 1)}
						>
							<BsArrowLeft/>
						</Button>
						<Button
							disabled={searchMatches.length === 0}
							onClick={() => setSearchIndex(searchIndex + 1)}
						>
							<BsArrowRight/>
						</Button>
					</div>
				</Stack>
			</Form>
			<div className="feed-content">
				<Form.Control
					as="textarea"
					readOnly={true}
					ref={contentRef}
					value={content}
					className="overflow-scroll border border-1 h-100"
				/>
				{
					loading && <div className="loading-overlay">
						<Loading/>
					</div>
				}
			</div>
		</div>
	);
}

export default FeedBrowserDetail;
